<template>
  <app-page name="Настройки страниц">
    <div class="top-content">
      <h1 class="page-title">Настройки страниц</h1>
    </div>
    <div class="page-settings__body">
      <div
        class="page-settings__item"
        v-for="settings in settings"
        :key="settings.id"
      >
        <div class="page-settings__item-image">
          <img :src="'img/' + settings.key + '-popup.jpg'" alt="" />
        </div>
        <div class="page-settings__item-key">
          {{ settings.key }}
          <span> {{ description[settings.key] }} </span>
        </div>
        <TextInput
          label="Popup timeout (ms)"
          placeholder="Timeout (ms)"
          :value="settings.popup_timeout"
          @update:value="timeouts[settings.key] = $event"
        />
        <input
          type="checkbox"
          :id="settings.key"
          @change="checked[settings.key] = $event.target.checked"
          :checked="settings.has_popup"
        />
        <Button
          text="Установить"
          className="btn-secondary"
          class="_filled"
          @click="update(settings.key)"
        />
      </div>
    </div>
  </app-page>
</template>

<script>
import AppPage from "@/components/AppPage";
import TextInput from "@/components/TextInput";
import Button from "@/components/Button";
import axios from "axios";
export default {
  components: {
    AppPage,
    TextInput,
    Button,
  },
  data() {
    return {
      timeouts: {
        item: null,
        feed: null,
        "category-feed": null,
      },
      checked: {
        item: null,
        feed: null,
        "category-feed": null,
      },
      settings: [],
      description: {
        item: "Попап на странице короткой/длинной новости",
        feed: "Попап на главной странице",
        "category-feed": "Попап на странице категорий",
      },
    };
  },
  async created() {
    this.load();
  },
  methods: {
    async update(key) {
      try {
        await axios.post(`/api/page-settings/${key}`, {
          popup_timeout: this.timeouts[key],
          has_popup: this.checked[key],
        });
        this.load();
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
    async load() {
      this.settings = [];
      try {
        const { data } = await axios.get("/api/page-settings");
        this.settings = data;
        data.forEach(s => {
          this.timeouts[s.key] = s.popup_timeout.toString();
          this.checked[s.key] = s.has_popup;
        });
      } catch (e) {
        this.$router.push(`/error/${(e.response && e.response.status) || 500}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.page-settings {
  &__body {
    margin-top: 20px;
  }
  &__item {
    margin-top: 20px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 15px;
    input {
      margin: 0 15px;
    }
    button {
      margin-top: 24px;
      @media screen and (max-width: 540px) {
        margin-top: 0;
      }
    }
    &-image {
      max-width: 200px;
      img {
        width: 100%;
      }
    }
    &-key {
      font-weight: 500;
      span {
        display: block;
        font-size: 14px;
        font-weight: 300;
        margin-top: 5px;
        max-width: 200px;
      }
    }
  }
}
</style>